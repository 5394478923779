var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('b-table',{attrs:{"loading":_vm.isLoading,"data":_vm.objectivesArray,"detailed":_vm.showDetails,"show-detail-icon":true,"default-sort":['id', 'asc'],"striped":""},scopedSlots:_vm._u([{key:"detail",fn:function(props){return [_c('div',{staticClass:"level-left"},[_c('p',{staticClass:"pr-3"},[_vm._v("Setter :")]),_c('SmartTag',{attrs:{"size":"is-small","tags":[
            { type: 'is-name', value: props.row.setter.name },
            { type: 'is-role', value: props.row.setter.role },
          ]}})],1),_c('div',{staticClass:"level-left mt-2"},[_c('p',{staticClass:"pr-3"},[_vm._v("Lead :")]),_c('SmartTag',{attrs:{"size":"is-small","tags":[
            { type: 'is-name', value: props.row.lead.name },
            { type: 'is-role', value: props.row.lead.role },
          ]}})],1),_c('div',{staticClass:"level-left mt-2"},[_c('p',{staticClass:"pr-3"},[_vm._v("Created :")]),_c('p',{staticClass:"has-text-weight-light"},[_vm._v(" "+_vm._s(_vm.DateTime.fromISO(props.row.creationDate).toLocaleString( _vm.DateTime.DATETIME_MED ))+" ")])]),_c('div',{staticClass:"level-left mt-2"},[_c('p',{staticClass:"pr-3"},[_vm._v("Deadline :")]),_c('p',{staticClass:"has-text-weight-light"},[_vm._v(" "+_vm._s(_vm.DateTime.fromISO(props.row.deadlineDate).toLocaleString( _vm.DateTime.DATETIME_MED ))+" ")])])]}}])},[_c('b-table-column',{attrs:{"field":"id","label":"ID","width":"40","sortable":"","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"objective","label":"Objective"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.name)+" ")]}}])}),(_vm.showCompleted)?_c('b-table-column',{attrs:{"field":"completed","label":"Completed","centered":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-checkbox',{attrs:{"disabled":!_vm.clickableCheckboxes},nativeOn:{"click":function($event){return _vm.checkboxToggle(props.row.id)}},model:{value:(props.row.completed),callback:function ($$v) {_vm.$set(props.row, "completed", $$v)},expression:"props.row.completed"}})]}}],null,false,3845025626)}):_vm._e(),(_vm.deleteableRows)?_c('b-table-column',{attrs:{"field":"delete","label":"","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('button',{staticClass:"button is-danger is-small is-outlined",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteObjective(props.row.id)}}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-times"})])])]}}],null,false,909099478)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }