<template>
  <section class="section">
    <div class="container">
      <div class="columns is-centered">
        <div class="column cardSize is-two-thirds box p-5">
          <!-- Main Content -->

          <!-- Card -->
          <!-- SmartTag -->
          <h1 class="pb-2 heading has-text-centered">New Task</h1>
          <div class="tag_box">
            <SmartTag class="py-3" :tags="mainTagInput" />
          </div>
          <hr />

          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <!-- Use form so enter to submit works -->
            <form
              @submit.prevent="handleSubmit(secondaryValidation)"
              novalidate="true"
            >
              <h1 class="heading has-text-centered mb-5">General</h1>

              <!-- project name -->
              <ValidationProvider
                rules="required_existing_project"
                name="projectValidator"
                v-slot="{ errors }"
              >
                <b-field
                  class="my-5"
                  label="Project"
                  label-position="on-border"
                  :type="{ 'is-info': errors[0] }"
                  :message="errors"
                >
                  <b-taginput
                    :has-counter="false"
                    v-model="task.project"
                    placeholder="..."
                    attached
                    maxlength="20"
                    maxtags="1"
                    type="is-project"
                    :data="filteredTags"
                    :allow-new="false"
                    open-on-focus
                    @typing="getFilteredTags"
                    autocomplete
                  >
                  </b-taginput
                ></b-field>
              </ValidationProvider>

              <!-- task name -->
              <ValidationProvider
                rules="required|name_min:5|name_max:20"
                name="taskValidator"
                v-slot="{ errors }"
              >
                <b-field
                  class="my-5"
                  label="Task Name"
                  label-position="on-border"
                  :type="{ 'is-info': errors[0] }"
                  :message="errors"
                >
                  <b-taginput
                    :has-counter="false"
                    v-model="task.name"
                    placeholder="..."
                    attached
                    maxlength="20"
                    maxtags="1"
                    type="is-task"
                  >
                  </b-taginput>
                </b-field>
              </ValidationProvider>

              <!-- skills -->
              <ValidationProvider
                rules="required"
                name="skillsValidator"
                v-slot="{ errors }"
              >
                <b-field
                  class="my-5"
                  label="Skills Required"
                  label-position="on-border"
                  :type="{ 'is-info': errors[0] }"
                  :message="errors"
                >
                  <b-taginput
                    v-model="task.skills"
                    placeholder="..."
                    attached
                    maxlength="25"
                    maxtags="10"
                    :has-counter="false"
                    type="is-skill"
                  >
                  </b-taginput>
                </b-field>
              </ValidationProvider>

              <!-- Deadline -->
              <ValidationProvider
                rules="required"
                name="deadlineValidator"
                v-slot="{ errors }"
              >
                <b-field
                  class="mt-5 pb-5"
                  label="Deadline"
                  label-position="on-border"
                  :type="{ 'is-info': errors[0] }"
                  :message="errors"
                >
                  <b-datetimepicker
                    placeholder="Click to select..."
                    icon="calendar-today"
                    v-model="deadlineDateObject"
                    :min-datetime="deadlineLimits.min"
                    :max-datetime="deadlineLimits.max"
                    :timepicker="{
                      incrementMinutes: 15,
                    }"
                  >
                  </b-datetimepicker>
                </b-field>
              </ValidationProvider>
              <hr />

              <!-- Description -->
              <h1 class="heading has-text-centered mb-5">Description</h1>
              <ValidationProvider
                rules="required"
                name="descriptionValidator"
                v-slot="{ errors }"
              >
                <b-field
                  class="my-5"
                  :type="{ 'is-info': errors[0] }"
                  :message="errors"
                >
                  <b-input
                    type="textarea"
                    maxlength="500"
                    v-model="task.description"
                  ></b-input>
                </b-field>
              </ValidationProvider>
              <hr />

              <!-- Objectives-->
              <h1 class="heading has-text-centered mb-5">Objectives</h1>

              <!-- objective input -->
              <b-field
                label="New Objective"
                label-position="on-border"
                class="my-5"
                :type="{ 'is-info': objectiveInputErrors[0] }"
                :message="objectiveInputErrors[0]"
                :key="objectiveKey"
              >
                <b-input
                  expanded
                  placeholder="..."
                  maxlength="200"
                  v-model="objectiveInput"
                  @blur="activateObjectiveInputErrors()"
                  v-on:keydown.enter.native.prevent="addObjective()"
                ></b-input>
                <p class="control">
                  <button
                    class="button is-primary"
                    type="button"
                    @mousedown.prevent
                    @click="addObjective()"
                  >
                    <span class="icon"> <i class="fas fa-plus"></i> </span>
                  </button>
                </p>
              </b-field>

              <ObjectivesTable
                v-if="!isEmpty(task.objectivesLog.objectives)"
                :objectivesLog="task.objectivesLog"
                :showDetails="true"
                :showCompleted="true"
                :deleteableRows="true"
                :clickableCheckboxes="true"
                @deleteObjective="deleteObjective"
                @checkboxToggle="checkboxToggle"
                :key="refreshKeys.objectivesTable"
              />
              <hr />

              <!-- Create button -->
              <div class="tag_box">
                <button
                  type="submit"
                  @click="activateObjectiveInputErrors()"
                  class="is-primary is-outlined mt-3 button"
                >
                  Create
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ObjectivesTable from "@/components/ObjectivesTable.vue";
import SmartTag from "@/components/SmartTag.vue";
import DateTime from "luxon/src/datetime";

import isEmpty from "lodash/isEmpty";
import { v4 as uuid } from "uuid";
import {
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";

export default {
  name: "CreateTask",

  components: {
    SmartTag,
    ObjectivesTable,
    ValidationObserver,
    ValidationProvider,
  },

  created() {
    setInteractionMode("eager");
    this.filteredTags = [...this.tags];
  },

  data() {
    return {
      tags: ["Bannana For Scale", "Motorbike Madness", "Jelly Robots"],
      filteredTags: [],
      deadlineLimits: {
        min: DateTime.now().plus({ minutes: 10 }).toJSDate(),
        max: DateTime.now().plus({ years: 2 }).toJSDate(),
      },
      objectiveKey: true,
      objectiveInput: "",
      objectiveInputActive: false,
      refreshKeys: {
        objectivesTable: uuid(),
      },
      task: {
        name: [],
        project: [],
        skills: [],
        deadline: null,
        description: "",
        setter: "UsernameGoesHere",
        objectivesList: [],
        objectivesLog: {},
      },
    };
  },

  methods: {
    isEmpty,
    getFilteredTags(text) {
      this.filteredTags = this.tags.filter((option) => {
        return option.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    secondaryValidation() {
      if (!this.objectiveInputErrors[0]) {
        this.createTask();
      }
    },

    createTask() {
      // Write code here for sending task to database

      // packageData()
      // SendData()
      this.$buefy.toast.open("Task Created ");
    },

    activateObjectiveInputErrors() {
      this.objectiveInputActive = true;
      // Force reset of objective input field by changing key
      this.objectiveKey = !this.objectiveKey;
    },

    reID() {
      this.task.objectivesList.forEach(function (item, index) {
        item.id = index + 1;
      });
    },

    deleteObjective(id) {
      this.task.objectivesList.splice(
        this.task.objectivesList.findIndex(function (item) {
          return item.id === id;
        }),
        1
      );
      this.reID();
      this.activateObjectiveInputErrors();
    },

    addObjective() {
      // Check if input empty.
      if (this.objectiveInput !== "") {
        this.idCount++;
        this.task.objectivesList.push({
          completed: false,
          creationDate: DateTime.now().toISO(),
          deadlineDate: this.task.deadlineDate,
          id: this.newID,
          lead: { name: "LeadUSN", role: "UILead" },
          name: this.objectiveInput,
          setter: { name: this.username, role: this.role },
        });
        // Clear input field
        this.objectiveInput = "";
        this.objectiveInputActive = true;
      }
    },
  },

  computed: {
    objectiveInputErrors() {
      if (this.objectiveInputActive && !this.task.objectivesList[0]) {
        return ["Please add at least one objective"];
      } else {
        return [];
      }
    },

    newID() {
      return this.task.objectivesList.length + 1;
    },

    deadlineDateObject: {
      get() {
        if (this.task.deadline) {
          return DateTime.fromISO(this.task.deadline).toJSDate();
        } else {
          return null;
        }
      },
      set(newValue) {
        if (newValue) {
          this.task.deadline = newValue.toISOString();
        }
      },
    },

    mainTagInput() {
      return [
        { type: "is-project", value: this.task.project[0] },
        { type: "is-task", value: this.task.name[0] },
        { type: "is-skill", value: this.task.skills[0] },
        { type: "is-date", value: this.task.deadline },
      ];
    },
  },
};
</script>

<style scoped>
.tag_box {
  display: flex;
  justify-content: center;
}
.cardSize {
  max-width: 750px;
}
</style>
