<template>
  <CreateTask class="keep-footer-off-page" />
</template>

<script>
import CreateTask from "@/components/CreateTask.vue";

export default {
  name: "new-task-view",

  components: { CreateTask },

  data() {
    return {
      project: "",
    };
  },

  created() {
    //load project into input from url
    if (this.$route.params.project) {
      this.project = [this.$route.params.project];
    }
  },
};
</script>
