<template>
  <section>
    <!-- table -->
    <!-- table -->
    <b-table
      :loading="isLoading"
      :data="objectivesArray"
      :detailed="showDetails"
      :show-detail-icon="true"
      :default-sort="['id', 'asc']"
      striped
    >
      <!-- ID -->
      <b-table-column
        field="id"
        label="ID"
        width="40"
        sortable
        numeric
        v-slot="props"
      >
        {{ props.row.id }}
      </b-table-column>

      <!-- Objective -->
      <b-table-column field="objective" label="Objective" v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <!-- Checkbox -->
      <b-table-column
        v-if="showCompleted"
        field="completed"
        label="Completed"
        v-slot="props"
        centered
        sortable
      >
        <b-checkbox
          v-model="props.row.completed"
          @click.native="checkboxToggle(props.row.id)"
          :disabled="!clickableCheckboxes"
        />
      </b-table-column>

      <!-- Delete -->
      <b-table-column
        v-if="deleteableRows"
        field="delete"
        label=""
        centered
        v-slot="props"
      >
        <button
          type="button"
          @click="deleteObjective(props.row.id)"
          class="button is-danger is-small is-outlined"
        >
          <span class="icon is-small">
            <i class="fas fa-times"></i>
          </span>
        </button>
      </b-table-column>

      <!-- detail -->
      <template slot="detail" slot-scope="props">
        <!-- Setter -->
        <div class="level-left">
          <p class="pr-3">Setter :</p>
          <SmartTag
            size="is-small"
            :tags="[
              { type: 'is-name', value: props.row.setter.name },
              { type: 'is-role', value: props.row.setter.role },
            ]"
          />
        </div>

        <!-- Lead -->
        <div class="level-left mt-2">
          <p class="pr-3">Lead :</p>
          <SmartTag
            size="is-small"
            :tags="[
              { type: 'is-name', value: props.row.lead.name },
              { type: 'is-role', value: props.row.lead.role },
            ]"
          />
        </div>

        <!-- dates -->
        <div class="level-left mt-2">
          <p class="pr-3">Created :</p>
          <p class="has-text-weight-light">
            {{
              DateTime.fromISO(props.row.creationDate).toLocaleString(
                DateTime.DATETIME_MED
              )
            }}
          </p>
        </div>

        <div class="level-left mt-2">
          <p class="pr-3">Deadline :</p>
          <p class="has-text-weight-light">
            {{
              DateTime.fromISO(props.row.deadlineDate).toLocaleString(
                DateTime.DATETIME_MED
              )
            }}
          </p>
        </div>
      </template>
    </b-table>
  </section>
</template>

<script>
import SmartTag from "@/components/SmartTag.vue";
import valuesIn from "lodash/valuesIn";
import DateTime from "luxon/src/datetime";

export default {
  name: "ObjectivesTable",

  components: {
    SmartTag,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    deleteableRows: {
      type: Boolean,
      default: false,
      required: false,
    },

    clickableCheckboxes: {
      type: Boolean,
      default: false,
      required: false,
    },

    showDetails: {
      type: Boolean,
      default: false,
      required: false,
    },

    showCompleted: {
      type: Boolean,
      default: false,
      required: false,
    },

    objectivesLog: {
      type: Object,
      default: function () {
        return {};
      },
      required: false,
    },
  },

  data() {
    return { DateTime };
  },

  methods: {
    valuesIn,

    deleteObjective(id) {
      this.$emit("deleteObjective", id);
    },
    checkboxToggle(id) {
      this.$emit("checkboxToggle", id);
    },
  },
  computed: {
    objectivesArray() {
      return valuesIn(this.objectivesLog.objectives);
    },
  },
};
</script>
